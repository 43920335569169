@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap);
:root{
  font-family: 'Nunito Sans';
  font: 400;
  --dark-grey: #787878;
  --white: #fff;
  --nav-width: 30vw;
  background-color: #f5f5f5;
}
body{
  margin:0;
  padding:0;
}
h1{
  font-family: 'Poppins';
  font: 300;
}
h3{
  margin-bottom: 0px;
}
h4{
  margin-bottom: 5px;
  margin-top: 10px;
}
ul{
  margin: 0;
  padding-left: 20px;
}
ol{
  margin: 0;
  padding-left: 20px;
}
nav{
  height: 100vh;
  width: 30vw;
  width: var(--nav-width);
  position: fixed;
  transition: 600ms ease;
  overflow: scroll;
}
.nav-container{
  padding-top:10vh;
  padding-left: 3rem;
  padding-right: 3rem;
  color: #fff;
  color: var(--white);
  text-align: right;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 80vh;
}
.profile-pic{
  width: 8em;
  border-radius: 100%;
  margin-bottom: 2.5rem;
}
.nav-words{
  font-size: 1.5rem;
  font-family: 'Poppins';
  font: 300;
}
.nav-words-s{
  padding-top: 2vh;
  font-family: Balsamiq Sans;
}
.get-in-touch{
  padding-bottom: 5vh;
}
svg{
  font-size: 1.5rem !important;
  margin-left:1rem;
  cursor: pointer;
}
.border{
  border: 1px black solid;
}
a{
  text-decoration: none;
  color: inherit;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
.main-container{
  padding-top:10vh;
  margin-left: 30vw;
  margin-left: var(--nav-width);
  padding-left:3rem;
  padding-right: 8rem;
  text-align: left;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.time-box{
  font-family: Balsamiq Sans;
}
.para{
  margin-top: 2rem;
  font-family: Nunito Sans;
}
.margin-top{
  margin-top: 1rem;
}
.current{
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.recent-work{
  margin-bottom: 2rem;
}
.underline{
  text-decoration: underline;
}
.tool-belt{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 2rem;
  margin-top: 0rem;
}
.card{
  box-shadow:
      12px 12px 10px 0 rgba(0, 0, 0, 0.25),
      -5px -5px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}
@media screen and (max-width: 950px) {
  .app{
    display: flex;
    flex-direction: column;
  }
  nav{
    width: 100%;
    transition: 600ms ease;
    position: absolute;
  }
  .nav-container{
    padding-top:5vh;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #fff;
    color: var(--white);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95vh;
    align-self: center;
    border:1px solid white;
  }
  .get-in-touch{
    align-self: center;
    font-size: 1.25rem;
  }
  svg{
    font-size: 2rem !important;
    margin-left:1rem;
    cursor: pointer;
  }
  .main-container{
    margin-top: 95vh;;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left:0.5rem;
    padding-right: 0.5rem;
    text-align: left;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
  .time-box{
    font-family: Balsamiq Sans;
  }
  .para{
    margin-top: 2rem;
    font-family: Nunito Sans;
  }
  .margin-top{
    margin-top: 1rem;
  }
  .current{
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
  .recent-work{
    margin-bottom: 2rem;
  }
  .underline{
    text-decoration: underline;
  }
  .tool-belt{
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 2rem;
    margin-top: 0rem;
  }
  .card{
    box-shadow:
        12px 12px 10px 0 rgba(0, 0, 0, 0.25),
        -5px -5px 12px 0 rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
  }

}
